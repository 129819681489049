<template>
    <section>
        <div class="health-plan-settings-header">
            <div class="title">Configurações das tabelas</div>
            <div class="header-actions">
                <Loading :class="{ icon: true, reload: true, loading }" @click="() => !loading && getClinicHealthPlanTables()"/>
                <b-button 
                    variant="outline-primary"
                    @click="() => clinicHealthPlanTables.push({ table: { type: TABLE.TYPE_OTHER_EXPENSES }, sub_type: TABLE.TYPE_OTHER_EXPENSES })"
                >
                    Adicionar tabela de outras despesas
                </b-button>
            </div>
        </div>
        <b-table-simple responsive fixed class="table-tables">
            <thead>
                <tr>
                    <th width="150px">Tipo</th>
                    <th>Tabela</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(clinicHealthPlanTable, index) in clinicHealthPlanTables" :key="index">
                    <td>
                        <div>{{ TABLE.parseTypeToLabelGrouped(clinicHealthPlanTable.sub_type) }}</div>
                    </td>
                    <td>
                        <div class="actions">
                            <TableSelector
                                v-model="clinicHealthPlanTable.table"
                                :clinicHealthPlanTables="clinicHealthPlanTables"
                                :types="getTypes(clinicHealthPlanTable.sub_type)"
                                :clinicHealthPlan="clinicHealthPlan"
                                :placeholder="selectorPlaceholder(clinicHealthPlanTable.sub_types)"
                                @select="table => onSelectTable(clinicHealthPlanTable, table)"
                                @remove="(table) => onRemoveSelection(clinicHealthPlanTable, table)"
                            />
                            <Settings 
                                v-if="
                                    [TABLE.TYPE_PROCEDURE, TABLE.TYPE_MAT_MED_SIMPRO, TABLE.TYPE_MAT_MED_BRASINDICE]
                                        .includes(clinicHealthPlanTable.table?.type)
                                "
                                class="icon"
                                :class="{ disabled: !clinicHealthPlanTable.id }"
                                v-b-tooltip.hover title="Configurações"
                                @click="clinicHealthPlanTable.id && openTableSettingsModal(clinicHealthPlanTable)"
                            />
                            <Delete 
                                v-if="![TABLE.TYPE_PROCEDURE, TABLE.TYPE_MAT_MED_SIMPRO, TABLE.TYPE_MAT_MED_BRASINDICE]
                                    .includes(clinicHealthPlanTable.table?.type)" 
                                class="icon"
                                v-b-tooltip.hover title="Remover tabela"
                                @click="onRemoveTable(index)"
                            />
                            <a :href="`/tables/d/${clinicHealthPlanTable.table.id}`" v-if="clinicHealthPlanTable.table?.id">
                                <List 
                                class="icon"  
                                v-b-tooltip.hover title="Ver itens da tabela"
                                />
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </b-table-simple>

        <TableProcedureSettingsModal 
            :clinicHealthPlanTable="clinicHealthPlanTable"
            @updated="updatedProcedureSettings"
            @hidden="clinicHealthPlanTable = null"
        />
    </section>
</template>
<script>
import api from '@tables/api';
import * as TABLE from '@tables/utils/constants';

export default {
    components: {
        Loading: () => import('@/assets/icons/loading.svg'),
        TableSelector: () => import('@tables/components/TableSelector'),
        Settings: () => import('@tables/assets/icons/setting-tune.svg'),
        Delete: () => import('@/assets/icons/delete.svg'),
        TableProcedureSettingsModal: () => import('@tables/modals/TableProcedureSettingsModal'),
        List: () => import('@tables/assets/icons/list.svg')
    },
    props: {
        clinicHealthPlan: Object,
    },
    data() {
        return {
            TABLE,
            loading: false,
            clinicHealthPlanId: this.$route.params.id,
            clinicHealthPlanTables: this.getDefaultClinicHealthPlanTables(),
            clinicHealthPlanTable: null,
        }
    },
    async mounted() {
        await this.getClinicHealthPlanTables()
    },
    methods: {
        getDefaultClinicHealthPlanTables() {
            return [
                { table: { type: TABLE.TYPE_PROCEDURE }, sub_type: TABLE.TYPE_PROCEDURE },
                { table: { type: TABLE.TYPE_MAT_MED_SIMPRO }, sub_type: TABLE.TYPE_MATERIAL },
                { table: { type: TABLE.TYPE_MAT_MED_SIMPRO }, sub_type: TABLE.TYPE_MEDICINE},
                { table: { type: TABLE.TYPE_OTHER_EXPENSES }, sub_type: TABLE.TYPE_OTHER_EXPENSES },
            ]
        },
        selectorPlaceholder(subType) {
            const types = {
                [TABLE.TYPE_PROCEDURE]: 'Selecione a tabela de procedimentos',
                [TABLE.TYPE_MATERIAL]: 'Selecione a tabela de materiais',
                [TABLE.TYPE_MEDICINE]: 'Selecione a tabela de medicamentos',
                [TABLE.TYPE_OTHER_EXPENSES]: 'Selecione a tabela de outras despesas',
            }
            return types[subType] || 'Selecione uma tabela'
        },
        openTableSettingsModal(clinicHealthPlanTable) {
            this.clinicHealthPlanTable = clinicHealthPlanTable
            this.$bvModal.show('table-procedure-settings-modal')
        },
        getTypes(subType) {
            const types = {
                [TABLE.TYPE_PROCEDURE]: [TABLE.TYPE_PROCEDURE],
                [TABLE.TYPE_MATERIAL]: [TABLE.TYPE_MAT_MED_SIMPRO, TABLE.TYPE_MAT_MED_BRASINDICE],
                [TABLE.TYPE_MEDICINE]: [TABLE.TYPE_MAT_MED_SIMPRO, TABLE.TYPE_MAT_MED_BRASINDICE],
                [TABLE.TYPE_OTHER_EXPENSES]: [TABLE.TYPE_OTHER_EXPENSES],
            }
            return types[subType] || []
        },
        async getClinicHealthPlanTables() {
            this.loading = true
            try {
                const { data } = await api.getClinicHealthPlanTables(this.clinicHealthPlanId)
                this.clinicHealthPlanTables = this.getDefaultClinicHealthPlanTables()
                data.map(clinicHealthPlanTable => {
                    const index = this.clinicHealthPlanTables.findIndex(el => !el.table?.id && el.sub_type === clinicHealthPlanTable.sub_type)
                    if (index >= 0) this.clinicHealthPlanTables[index] = clinicHealthPlanTable
                    else this.clinicHealthPlanTables.push(clinicHealthPlanTable)
                })
            } catch(err) {
                this.$toast.error(err.message)
            } finally {
                this.loading = false
            }
        },
        onRemoveSelection(clinicHealthPlanTable, table) {
            const index = this.clinicHealthPlanTables.findIndex(el => el.id === clinicHealthPlanTable.id)
            clinicHealthPlanTable.id && this.removeTable(null, clinicHealthPlanTable.id)
            this.clinicHealthPlanTables[index] = { table: { type: table.type }, sub_type: clinicHealthPlanTable.sub_type }
        },
        async onSelectTable(clinicHealthPlanTable, table) {
            const payload = {
                id: clinicHealthPlanTable.id,
                clinic_health_plan_id: this.clinicHealthPlanId,
                table_id: table.id,
                sub_type: clinicHealthPlanTable.sub_type,
            }
            await this.save(payload)
            await this.getClinicHealthPlanTables()
        },
        async save(payload) {
            this.loading = true
            try {
                !payload.id ? await api.createClinicHealthPlanTable(payload) : await api.updateClinicHealthPlanTable(payload.id, payload)
                this.$toast.success('Tabela vinculada com sucesso')
            } catch(err) {
                this.$toast.error(err.message)
            } finally {
                this.loading = false
            }
        },
        removeTable(index, id) {
            this.loading = true
            api.deleteClinicHealthPlanTable(id)
                .then(() => {
                    index && this.clinicHealthPlanTables.splice(index, 1)
                    this.$toast.success('Tabela removida com sucesso')
                })
                .catch(err => this.$toast.error(err.message))
                .finally(() => (this.loading = false))
        },
        onRemoveTable(index) {
            const clinicHealthPlanTable = this.clinicHealthPlanTables[index]
            if (!clinicHealthPlanTable?.table?.id) {
                this.clinicHealthPlanTables.splice(index, 1)
                return
            }
            this.$swal({
                icon: 'question',
                html: `<div class="body-alert">Deseja realmente remover a tabela <span>${clinicHealthPlanTable.table.name}</span>?</div>`,
                showCancelButton: true,
                confirmButtonColor: '#F63220',
                confirmButtonText: 'Confirmar',
                cancelButtonText: 'Cancelar',
                reverseButtons: true
            }).then(res => {
                if (res.isConfirmed) {
                    if (clinicHealthPlanTable.id) {
                        this.removeTable(index, clinicHealthPlanTable.id)
                    }
                }
            })
        },
        updatedProcedureSettings(props) {
            const clinicHealthPlanTables = [...this.clinicHealthPlanTables]
            const index = clinicHealthPlanTables.findIndex(({ id }) => id === props.id)
            clinicHealthPlanTables.splice(index, 1, props)
            this.clinicHealthPlanTables = clinicHealthPlanTables
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.health-plan-settings-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    .title {
        font-family: 'Red Hat Display';
        font-weight: 700;
        font-size: 1.2rem;
        color: var(--blue-500);
    }
    .header-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }
}
.table-tables {
    min-height: 80vh;
}
.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    .icon {
        width: 24px;
        height: 24px;
        stroke: var(--blue-500);
        cursor: pointer;

        &.disabled {
            stroke: var(--neutral-500);
            cursor: not-allowed;
        }
    }
    .table-details {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        flex-direction: row;
    }
}
.add-table-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.table-type {
    color: var(--neutral-500);
}
</style>